.paragraphText{
    color: #828282;
    font-family: Inter;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px 20px 0px 20px;
    font-style: italic;
    font-weight: 400;
    margin: 0px;
    font-size: 20px;
}
.buttonAndContent{
    font-family: Inter;
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
}
.setButtonProperty{
    background: #1D4488 !important;
    border: none !important;
    font-weight: 600 !important;
}
.buttonContent{
    padding-top: 60px;
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .paragraphText{
      color: #828282;
      font-family: Inter;
      display: flex;
      justify-content: center;
      font-style: normal;
      font-size: small;
  }
  }