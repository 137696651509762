/*====Slider Css=====*/

/*===============================*/

.bannerHeading {
  width: 100%;
  max-width: 600px;
  border: 1px solid #5da128;
  background: #5da128;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  top: 65%;
  right: 0;
}

.bannerHeading h5 {
  margin-bottom: 0px;
  font-size: 33px;
  font-weight: 800;
  color: #fff;
}

.headerSpace {
  padding-top: 84px;
}

/*==================Home1==========================*/
.textStyle {
  margin: 40px auto;
  max-width: 800px;
  text-align: center;
}

.textStyle p {
  color: #828282;
  font-size: 16px;
  margin: 15px 0px;
  font-weight: 500;
}

span.borderLine {
  width: 60px;
  height: 6px;
  background: #5da128;
  /* color: #000; */
  position: absolute;
  border-radius: 10px;
  content: "";
}

.textStyle h3 {
  font-size: 35px;
  color: #333;
  font-weight: 800;
  max-width: 810px;
  text-align: center;
  line-height: 50px;
}

.textStyle span {
  color: #5da128;
}

/*============Home2===============*/

.aboutCaregiver {
  background-image: url("../../Assests/Images/bgImage1.png");
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  margin: 180px 0px 0px 0px;
  background-position: center;
}

.aboutImg Img {
  max-width: 480px;
  margin-top: -120px;
  width: 100%;
}

.tagIdSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutTxt h3 {
  color: #fff;
}

.aboutTxt {
  max-width: 500px;
  margin: 36px 0px;
  line-height: 52px;
}

.aboutSec .row {
  align-items: center;
}

.aboutTxt p {
  color: #f2f2f2 !important;
  line-height: 39px;
  font-weight: 300;
  margin: 15px 0px;
}

.tagSec span {
  color: #f5f5f5;
  margin-left: 16px;
  font-size: 16px;
}

.tagIdSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
}

.aboutTxt h3 {
  color: #fff;
  font-size: 39px;
  font-weight: 700;
  line-height: 56px;
}

.tagSec {
  align-items: center;
  display: flex;
}

.tagSec a {
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  .aboutCaregiver {
    margin: 60px 0px 0px 0px;
  }

  .aboutImg Img {
    max-width: 480px;
    margin-top: 0px;
  }

  .aboutTxt h3 {
    font-size: 30px;
    line-height: 40px;
  }

  .tagIdSec {
    height: 42px;
  }

  .mauto {
    margin: auto;
  }

  .aboutTxt p {
    line-height: 35px;
    margin: 15px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .aboutCaregiver {
    margin: 60px 0px 0px 0px;
  }

  .aboutImg Img {
    max-width: 320px;
    margin-top: 25px;
  }

  .aboutTxt h3 {
    font-size: 25px;
    line-height: 37px;
    text-align: center;
  }

  .mauto {
    margin: auto;
  }

  .aboutTxt p {
    text-align: center;
    line-height: 31px;
    margin: 10px 0px;
  }

  .bannerHeading {
    max-width: 440px;
    height: 80px;
  }

  .bannerHeading h5 {
    font-size: 25px;
  }

  .aboutTxt {
    margin: 20px 0px;
  }

  .aboutImg {
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  .aboutImg Img {
    max-width: 220px;
    margin-top: 25px;
  }

  .tagIdSec {
    height: 39px;
  }

  .aboutTxt p {
    line-height: 26px;
    margin: 10px 0px;
    font-size: 14px;
  }

  .textStyle h3 {
    font-size: 25px;

    line-height: 40px;
  }

  .bannerHeading h5 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 330px) {
  .tagSec span {
    font-size: 14px;
  }
}
.aboutSec {
  padding-bottom: 60px;
}
