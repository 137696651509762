.addSiteProperty {
  display: flex;
  justify-content: end;
  background-color: #5da128 !important;
  border: none !important;
  font-family: "Inter";
}
.modelText {
  font-family: "Inter";
  font-size: 25px !important;
  font-weight: 700 !important;
  line-height: 30.26px;
  text-align: left;
  /* color: #000000; */
}
.noRecord p {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.noRecord {
  width: 100%;
  text-align: center !important;
  padding: 180px 0px;
  text-wrap: nowrap;
  max-width: 100%;
}
.modelTitle {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  color: #000000;
}

  .eventTable {
    width: 100%;
    height: 55vh;
    overflow: auto;
    background-color: #f5f5f5;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
    border-radius: 6px;
  }
  
  .customTable th:last-child {
    border-top-right-radius: 6px;
    display: flex;
    justify-content: flex-end;
    border: none;
    height: 50px;
    align-items: center;
  }
  .tableHeadProperty tr th {
    background: #202020;
    color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 12px;
  }  
  p.Eventproperty {
    font-size: 22px;
    font-weight: 700;
    color: #000;
  }
  .deleteClass {
    text-align: center;
  }
  
  .deleteClass p {
    font-size: 18px;
    width: 100%;
  
    font-weight: 700;
    padding: 0px 49px;
    margin: 20px 0px;
  }
