.addDeviceProperty {
  padding-bottom: 10px;
}
.addDeviceProperty label {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #333333;
}
.addDeviceProperty .textSize input.form-control {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
}
.addDeviceProperty input {
  height: 50px;
}
.addDeviceProperty select {
  height: 50px;
}
.addDeviceProperty select .selectIconProperty {
  position: relative;
  top: 31px;
  left: -6px;
}
.addDeviceProperty input::placeholder {
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
}
.addDeviceProperty select {
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
}

.CalenderIconProperty {
  width: 30px;
  height: 30px;
  position: relative;
  top: -50px;
  left: 425px;
}

.inputStyle {
  height: 50px !important;
  font-size: 15px;
  font-weight: 400;
}
.personField {
  position: relative;
}
.vectorIcon {
  position: absolute;
  top: 21px;
  right: 5px;
}
.selectCustom {
  padding-top: 15px;
}
.customFields {
  margin-top: -18px;
}
.personLabel {
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}
.personField input::placeholder {
  font-family: "Inter";
  font-size: 15px !important;
}
.noneContent {
  /* border: 1px solid #FAFAFA; */
  border: 1px solid #e3e3e3;
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 500;
}
.radioContainer {
  display: flex;
  justify-content: space-between;
}
.timeLabel {
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}
.reminder {
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
  padding-bottom: 4px;
}
.customLabel {
  font-size: 15px;
  font-weight: 500;
  font-family: "Inter";
}
.textArea {
  font-size: 15px;
  font-weight: 400;
  font-family: "Inter";
}
.textArea::placeholder {
  color: #555555;
  font-size: 15px;
  font-weight: 400;
}
.selectLevel {
  font-size: 15px;
  font-weight: 400;
  font-family: "Inter";
  color: #555555 !important;
}
/* .modelTitle {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  color: #000000;
} */

.personListContainer {
  max-height: 250px; /* Set a height that makes sense for your modal */
  min-height: 50px;
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Optional: Add some spacing */
  scrollbar-width: thin;
  padding-right: 6px;
}
