.NotificationUnread {
  background-color: rgba(230, 230, 230, 0.47);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  cursor: pointer;
  display: flex;
}

.NotificationRead {
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  cursor: pointer;
  display: flex;
}

.NotificationRead h5 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 6px;
}

.NotificationRead h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.NotificationRead p {
  font-size: 10px;
  font-weight: 400;
  color: #828282;
}

.NotificationUnread h5 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 6px;
}

.NotificationUnread h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.NotificationUnread p {
  font-size: 10px;
  font-weight: 400;
  color: #828282;
}

.notificationImg {
  width: 32px;
  height: 32px;
  color: white;
  cursor: pointer;
}

.topIcon {
  display: flex;
  align-items: center;
}

.notificationSection {
  position: relative;
}

.notificationCount {
  position: absolute;
  right: 4px;
  top: 0px;
  background: #ec3b1e;
  border-radius: 50%;
  padding: 5px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}

.noResultsMessage {
  text-align: center;
}

.deleteIcon {
  color: red;
  height: 20px;
  width: 20px;
}
