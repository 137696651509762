.addSiteProperty {
  display: flex;
  justify-content: end;
  background-color: #5da128 !important;
  border: none !important;
  font-family: Inter;
}
.noRecord p {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.noRecord {
  width: 100%;
  text-align: center !important;
  padding: 180px 0px;
  text-wrap: nowrap;
  max-width: 100%;
  /* position: absolute; */
}

th.permission {
  text-align: left !important;
}

.eventTable {
  width: 100%;
  height: 55vh;
  overflow: auto;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
}

.customTable th:last-child {
  border-top-right-radius: 6px;
  display: flex;
  justify-content: flex-end;
  border: none;
  height: 50px;
  align-items: center;
}
.tableHeadProperty tr th {
  background: #202020;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 12px;
}
p.Eventproperty {
  font-size: 22px;
  font-weight: 700;
  color: #000;
}
.deleteClass {
  text-align: center;
}

.deleteClass p {
  font-size: 18px;
  width: 100%;

  font-weight: 700;
  padding: 0px 49px;
  margin: 20px 0px;
}
.placeholder {
  color: #999;
  font-size: 15px;
  font-weight: 400;
  font-family: "Inter";
}
.inputLabel.hidden {
  display: none;
}
.inputLabelPlace {
  font-size: 15px;
  font-weight: 400;
  font-family: "Inter";
}

.personListContainer {
  max-height: 250px; /* Set a height that makes sense for your modal */
  min-height: 50px;
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Optional: Add some spacing */
  scrollbar-width: thin;
  padding-right: 6px;
}
.hourModal {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.hourModal p {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.hourModalText {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.hourModalText p {
  color: #828282;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 0;
}

.hourModalText p:last-of-type {
  color: #333;
  font-size: 500;
  font-size: 18px;
  font-weight: 500;
}

.closeText {
  color: red !important;
}

p.viewHour {
  color: #5c5c5c;
  font-weight: 600;
  cursor: pointer;
}
.noRecord p {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
}
.noRecord {
  width: 100%;
  text-align: center !important;
  padding: 180px 0px;
  text-wrap: nowrap;
  max-width: 100%;
}

.personLabel {
  font-family: "Inter";
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21.78px !important;
  text-align: left;
  color: #333333;
}

.personField input::placeholder {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
}
