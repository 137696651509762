.editProfile {
  padding: 20px 20px;
}
.setIconProperty {
  margin-right: 15px;
  cursor: pointer;
}
.headingTextProperty {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  /* display: flex;
    justify-content: center; */
}
.headingTextProfile {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
.profileContainer {
  text-align: center;
}
.formContainer {
  display: flex;
  justify-content: center;
}
.registerFormsField {
  width: 450px;
  /* padding-top: 20px; */
  padding-top: 50px;
}
.formInputFields {
  width: 450px;
}
.buttonContainer {
  padding-top: 20px;
  display: flex;
  justify-content: end;
  color: #1d4488;
}
.buttonContainer .buttonProperty {
  background-color: #1d4488 !important;
  border: none;
  font-family: Inter;
  /* font-size: 17px;
    font-weight: 600; */
  padding: 8px 22px;
}
.buttonProperty {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
}
.imageUrlProperty {
  width: 110px;
  height: 110px;
  border: 1px dashed #adadad;
}
.setButton {
  width: 24px !important;
  height: 24px !important;
  background-color: #1d4488;
  border-radius: 50% !important;
  /* position: relative; */
  /* top: -29px;
    right: -78px; */
  /* top: -22px; */
  /* right: -246px; */
  position: relative;
  top: -25px;
  left: 75px;
}
.uploadimageContainer {
  display: flex;
  justify-content: center;
}

.uploadButton {
  color: #ffffff;
  background-color: #1d4488;
  border-radius: 50% !important;
  /* width: 22px !important;
    height: 22px !important;
    padding: 0px; */
  width: 15px !important;
  height: 15px !important;
  margin-top: -2px;
  margin-left: 0px;
}

@media screen and (max-width: 480px) {
  .registerFormsField {
    width: auto;
    /* padding-top: 20px; */
    padding-top: 50px;
  }
  /* .formInputFields{
        max-width: 450px;
    } */
}
