.privacyPolicy {
  /* margin-top: 120px;
  text-align: center; */
  margin-top: 120px;
  text-align: center;
  /* background-color: #f9f9f9;
  padding: 30px;
  border-radius: 6px; */
}
.privacyPolicy h4 {
  /* color: #000; */
  color: #1d4488;
  font-size: 32px;
  font-weight: 600;
}
.policyText {
  /* margin-top: 28px;
  text-align: left; */
  margin-top: 28px;
  text-align: left;
  color: #515151;
  font-size: 16px;
}

.policyText p {
  line-height: 30px;
}
