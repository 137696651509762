.addDeviceProperty {
  padding-bottom: 10px;
}
.addDeviceProperty label {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #333333;
}
.addDeviceProperty input {
  height: 50px;
}
.addDeviceProperty select {
  height: 50px;
}
.addDeviceProperty select .selectIconProperty {
  position: relative;
  top: 31px;
  left: -6px;
}
.addDeviceProperty input::placeholder {
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
}
.addDeviceProperty select {
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
}

.CalenderIconProperty {
  width: 30px;
  height: 30px;
  position: relative;
  top: -50px;
  left: 425px;
}

.inputStyle {
  height: 50px !important;
}
.personField {
  position: relative;
}
.vectorIcon {
  position: absolute;
  top: 21px;
  right: 5px;
}
.personLabel {
  font-size: 18px;
  font-weight: 500;
  font-family: Inter;
}
.personField input::placeholder {
  font-family: Inter;
  font-size: 15px;
}

.personListContainer {
  max-height: 250px; /* Set a height that makes sense for your modal */
  min-height: 50px;
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Optional: Add some spacing */
  scrollbar-width: thin;
  padding-right: 6px;
}
/* .modelTitle {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  color: #000000;
} */
