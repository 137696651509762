th.settingBackground {
  background-color: #000;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

th.settingBackgroundAction {
  background-color: #000;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-align: right !important;
  line-height: 18px;
}

td.caregiverSourceText {
  font-size: 15px;
  font-weight: 500;
  font-family: "Inter";
  color: #202020;
  line-height: 19px;
}

.settingDotImage {
  text-align: end;
}
.setEventDropdown {
  padding: 0px 10px !important;
  color: #000000;
  background-color: transparent;
  justify-content: end;
  display: flex;
}
.setEventDropdown.dropdown-menu.show {
  height: 20px !important;
}
a.caregiverdropdownItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.listRecoredText {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-top: 40px;
}
.settingPermissionTable {
  background-color: #f5f5f5;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  height: 50vh;
  overflow: auto;
}
.settingPermissionTable table thead tr {
  height: 50px;
}
.addNewTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d4488;
  border-radius: 6px;
  margin: 20px 20px 20px 20px;
  padding: 0px;
  height: 50px;
}

.addNewTitle button {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #fff !important;
  width: 100%;
  border: none;
}
