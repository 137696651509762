li {
  list-style-type: none;
}

ul {
  padding-left: 0px;
}

.tbl {
  width: 100%;
  max-width: 100%;
}

.alignment {
  display: flex;
  width: 100%;
}

.thead tr th {
  background-color: #000;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

tr {
  font-size: 15px;
  font-weight: 500;
  color: #202020;
  vertical-align: middle;
}

.search-form {
  margin-top: 20px;
}

.search-form .form-control {
  width: 300px;
}

.searchFeild {
  margin: 25px 0px;
  position: relative;
}

.searchFeild input {
  height: 50px;
  max-width: 370px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
}

.tbl {
  width: 100%;
  padding: 0px 20px;
}
/* .tableGap{
  padding: 0px 20px;
} */
.searchFeild svg {
  font-size: 20px;
  position: absolute;
  top: 15px;
  left: 15px;
  color: #959595;
}

tr th:first-child {
  border-top-left-radius: 6px;
}

tr th:last-child {
  border-top-right-radius: 6px;
}

button#dropdownAction {
  background: none !important;
}

.dropdown-toggle::after {
  display: none;
}

button#dropdownAction {
  background: none !important;
}

.editIcon,
.deleteIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textCenter {
  text-align: center;
}

.hyt {
  width: 20%;
}

.hyt1 {
  width: 80%;
}

@media screen and (max-width: 767px) {
  .hyt1 {
    width: 100% !important;
  }
}

.noRecord p {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  /* text-align: center; */
}
.noRecord {
  width: 100%;
  text-align: center !important;
  padding: 180px 0px;
  text-wrap: nowrap;
  max-width: 100%;
}
