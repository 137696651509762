.activeTab {
  color: #1d4488;
  font-weight: 700;
  font-size: 16px;
  padding: 8px;
}

.inactiveTab {
  color: #626262;
  font-weight: 700;
  padding: 8px;
}

.signOut {
  color: #5da128;
  font-weight: 700;
  padding: 8px;
}

.mainHeader {
  z-index: 1;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid #f1f1f1;
}

/*===============Admin Header=====================*/

.stakeheader {
  height: 80px;
  background-color: #1d4488;
  justify-content: end;
  display: flex;
  align-items: center;
}

.profileImage {
  width: 48px;
  height: 48px;
  border: 2px solid #fff;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  cursor: pointer;
}

.profileImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
