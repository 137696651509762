.addDeviceProperty {
  /* padding-bottom: 20px; */
  padding-bottom: 20px;
  /* width: 100%; */
}

.addDeviceProperty label {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #333333;
}

.addDeviceProperty input {
  height: 50px;
}

.addDeviceProperty select {
  height: 50px;
}

.addDeviceProperty select .selectIconProperty {
  position: relative;
  top: 31px;
  left: -6px;
}

.addDeviceProperty input::placeholder {
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
}

.addDeviceProperty select {
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
}

.CalenderIconProperty {
  width: 30px;
  height: 30px;
  position: relative;
  top: -50px;
  left: 425px;
}

.imageUrlProperty {
  width: 110px;
  height: 110px;
  border: 1px dashed #adadad;
  /* margin-left: 103px; */
}

.setButton {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  position: relative;
  top: -25px;
  left: 75px;
  /* left: 177px; */
}

.uploadimageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* .profileContainer{
position: relative;
} */

.uploadButton {
  color: #ffffff;
  background-color: #1d4488;
  border-radius: 50% !important;
  width: 22px !important;
  height: 22px !important;
  padding: 0px;
}

.headingTextProfile {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 20.57px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.setTextPlace input::placeholder {
  padding-left: 25px;
}

.setTextPlace {
  position: relative;
}

.noneContent {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .registerFormsField {
    width: auto;
    padding-top: 50px;
  }
}

.radioCheck label {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}

.audioIntro h4 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.errorMessage {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 15px;
}

.addDeviceProperty.langSelect {
  margin-top: -10px;
}
p.promptText {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  /* text-align: left; */
  color: #333333;
}

p.audioFile {
  margin-bottom: 15px;
}

.defaultToneSection {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.defaultTone {
  font-weight: 600;
}
