.activeDeactiveProperty {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  padding: 20px;
  text-align: center;
  font-weight: 700;
}
.modelTitle {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  color: #000000;
}
