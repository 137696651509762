.custom-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: border-color 0.3s ease;
  /* background-color: red; */
}

.custom-file-upload:hover {
  border-color: #80bdff;
}

.upload-icon {
  margin-right: 0.5rem;
}

.custom-file-upload span {
  margin-left: 0.5rem;
}
.eventTable {
  width: 100%;
  height: 55vh;
  overflow: auto;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
}

.mainDiv {
  margin-right: 15;
  margin-left: 15;
  border-width: 1;
  border-color: #dcdcdc;
  border-radius: 6;
  padding: 10;
}
.parentIconButton {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 6px;
  position: absolute;
  margin-top: 36px;
  align-items: center;
  width: 100%;
  top: 0px;
}
.audioImage {
  width: 15px;
  margin-bottom: 5px;
}
.browseSpeakerButton {
  display: flex;
  align-items: center;
}
.audioSpeakerContainer {
  margin-right: 10px;
}
.audioSpeakerContainer {
  margin-right: 10px;
}
.selectAudioButton {
  background-color: #1d4488;
  color: #ffffff;
  padding: 7px 25px;
  height: 40px;
}
.audioImageContainer {
  /* position: absolute;
  bottom: 40px;
  left: 25px; */
}
.audioButtonContainer {
  /* position: absolute;
  right: 20px;
  bottom: 31px; */
}

.fixed-height-textarea {
  height: 400px; /* Set the desired height here */
  resize: none; /* Prevent the user from resizing the textarea */
}
.addDeviceProperty {
  /* padding-bottom: 20px; */
  margin-top: 15px;
  /* width: 100%; */
}
.addDeviceProperty label {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #333333;
}
.addDeviceProperty input {
  height: 50px;
}
.addDeviceProperty select {
  height: 50px;
}
.addDeviceProperty select .selectIconProperty {
  position: relative;
  top: 31px;
  left: -6px;
}
.addDeviceProperty input::placeholder {
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
}
.addDeviceProperty select {
  font-size: 15px;
  font-weight: 400;
  font-family: Inter;
}

.CalenderIconProperty {
  width: 30px;
  height: 30px;
  position: relative;
  top: -50px;
  left: 425px;
}
.imageUrlProperty {
  width: 110px;
  height: 110px;
  border: 1px dashed #adadad;
}
.setButton {
  width: 24px !important;
  height: 24px !important;
  background-color: #1d4488;
  border-radius: 50% !important;
  position: relative;
  top: -25px;
  left: 75px;
}
.uploadimageContainer {
  display: flex;
  justify-content: center;
}

.uploadButton {
  color: #ffffff;
  background-color: #1d4488;
  border-radius: 50% !important;
  width: 22px !important;
  height: 22px !important;
  padding: 0px;
}
.headingTextProfile {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 20.57px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.setTextPlace input::placeholder {
  padding-left: 25px;
}
.saddDeviceProperty input::placeholder {
  padding-left: 170px !important;
}
.addDeviceProperty::placeholder {
  padding-left: 170px !important;
}
.setTextPlace {
  position: relative;
}
.noneContent {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 480px) {
  .registerFormsField {
    width: auto;
    padding-top: 50px;
  }
}

.noRecord p {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.noRecord {
  width: 100%;
  text-align: center !important;
  padding: 180px 0px;
  text-wrap: nowrap;
  max-width: 100%;
}
.personField {
  position: relative;
}
.personField input::placeholder {
  font-family: "Inter";
  font-size: 15px;
}
.personLabel {
  font-size: 18px;
  font-weight: 500;
  font-family: Inter;
}
.inputStyle {
  height: 50px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  cursor: pointer;
}

.personListContainer {
  max-height: 250px; /* Set a height that makes sense for your modal */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Optional: Add some spacing */
  scrollbar-width: thin;
  padding-right: 6px;
}
p.Eventproperty {
  font-size: 22px;
  font-weight: 700;
  color: #000;
}
.deleteClass {
  text-align: center;
}

.deleteClass p {
  font-size: 18px;
  width: 100%;

  font-weight: 700;
  padding: 0px 49px;
  margin: 20px 0px;
}