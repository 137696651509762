.addSiteProperty{
    display: flex;
    justify-content: end;
    background-color:#5DA128 !important;
    border: none  !important;
    font-family: Inter;
}

.sidePadding{
    padding:10px !important;
    height: 80vh;
    overflow-y: auto;
}

.setLogoOnDashboard {
    padding: 8px 20px 8px 20px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid;
    border-bottom: 1px solid #DFDFDF !important;
}

#dropdown-basic {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

#dropdown-basic:focus-visible {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}



