.footerSec{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.footerSec ul {
    padding-left: 0px;
    display: flex;
    list-style-type: none;
}

.footerSec h3{
    color:#202020;
    font-size:13px;
    margin-bottom: 0px;
}

.mainFooter{
    width:100%;
    background-color: #f1f1f1;
    padding:18px 0px;
}

.socialLink span a {
    text-decoration: none;
    color: #202020;
    font-size: 13px;
    font-weight: 500;
    
}
.socialLink span {
    margin-right: 16px; 
  }
  
  .socialLink span:last-child {
    margin-right: 0;
  }

@media only screen and (max-width: 530px) {
    .footerSec {
     display: block;
     text-align: center;
       }
    .mainFooter{
        padding:16px 0px;
    }
    .footerSec h3 {
        margin:5px 0px;
    }
    }




