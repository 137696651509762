.stakeheader {
    height: 80px;
    background-color: #1D4488;
    justify-content: end;
    display: flex;
    align-items: center;
}

.profileImage {
    width: 48px;
    height: 48px;
    border: 2px solid #fff;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    cursor: pointer;
}

.profileImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.sideBar {
    border: 1px solid #DFDFDF;
    height: 100%;
    width: 100%;
    max-width: 300px;
    height: 713px;
    margin-top: -80px;
}

li {
    list-style-type: none;
}

.logoBox {

    text-align: center;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #dfdfdf;
}

ul {
    padding-left: 0px;
}


.listTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
    cursor: pointer;
}


.listTab p {
    margin-bottom: 0px;
    margin-bottom: 0px;
    color: #5DA128;
    font-size: 15px;
    font-weight: 700;
}

.listTab p::before {
    content: '';
    width: 3px;
    height: 23px;
    background-color: #5DA128;
    top: 20px;
    position: absolute;
    border-radius: 3px;
    left: 10px;
}

.listTab svg {
    color: #5DA128;
}

.tbl {
    width: 100%;
}

.alignment {
    display: flex;
}

.thead tr th {
    background-color: #000;
    color: white;
    font-size: 16px;
    font-weight: 500;
}

tr {
    font-size: 15px;
    font-weight: 500;
    color: #202020;
}

.search-form {
    margin-top: 20px;
}

.search-form .form-control {
    width: 300px;
}

.searchFeild {
    margin: 25px 0px;
    position: relative;

}


.tbl {
    width: 100%;
    padding: 0px 20px;
}


tr th:first-child {
    border-top-left-radius: 6px;
   
}

tr th:last-child {
    border-top-right-radius: 6px;
    text-align: center;
}




button#dropdownAction {
    background: none !important;

}

.dropdown-toggle::after {
    display: none;
}

button#dropdownAction {
    background: none !important;
}

.editIcon,
.deleteIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backArrow {
    padding: 25px 0px;
    display: flex;
    align-items: center;
}

.backArrow span {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    margin-left: 8px;
}

.backArrow svg {
    color: #000;
    font-size: 26px;
}

.backArrowSec {
    max-width: 260px;
    width: 100%;
}


/*=============New css===================*/

.infoSection h3 {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.mainInfo_sec {
    display: flex;
    border: 1px solid #DDDDDD;
    padding: 15px;
  }

.mainInfo_sec .userInfo {
    width: 50%;
}

.userInfo h4,
.userInfo1 h4 {
    font-size: 12px;
    color: #626262;
    font-weight: 400;
}

.userInfo p,
.userInfo1 p {
    font-size: 15px;
    color: #202020;
    font-weight: 500;
    margin-bottom: 0px;
}

.userInfo1 {
    position: relative;
    padding-left: 20px;
    width: 50%;

}

.userInfo1::before {
    content: '';
    width: 2px;
    height: 45px;
    position: absolute;
    top: 3px;
    background-color: #B9B9B9;

    margin-right: 20px;
    left: 0px;
}

ul#justify-tab-example {

    background: #F1F1F1;
}

.mtb20 {
    margin: 20px 0px;
}

.borderTop {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.borderBottom {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.center {
    text-align: center;
}

/*========Message-Level=========*/

.msgList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.msgList span {
    font-weight: 600;
    font-size: 15px;
    color: #202020;
}

.changeLevel button {
    padding: 10px 15px;
}

.tableResizer {
    width: 100%;
 
}

.SelectLevel {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}

.selectTitle {
    font-size: 25px;
    font-weight: 700;
    color: #000;
}

.levelCount {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #e7e7e7;
    padding: 10px;
}

.selectFeild img {
    position: absolute;
    right: 20px;
    top: 20px;
}

.selectFeild {
    position: relative;
    display: flex;
    align-items: center;
}

.levelCount {
    margin-right: 8px;
    color: #555;
    font-size: 15px;
    font-weight: 400;
}

.rotatedImage {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
.deleteBtn{
    background-color: "red" !important;
    border-color:"red";
}
p.Eventproperty {
    font-size: 22px;
    font-weight: 700;
    color: #000;
  }
  .deleteClass {
    text-align: center;
  }
  
  .deleteClass p {
    font-size: 18px;
    width: 100%;
  
    font-weight: 700;
    padding: 0px 49px;
    margin: 20px 0px;
  }