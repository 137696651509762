.mainUser {
    background: #fff;
    padding: 10px;
    justify-content: center;
    display: flex;
    width: 100%;
    max-width: 650px;
    border-radius: 10px;
    margin-bottom: 15px;
    height: 90px;
    align-items: center;
  }
  .userType {
    justify-content: center;
    background-image:url('../../Assests/Images/usertype.png');
    width: 100%;
    height: 100vh;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    padding: 50px 10px;
    overflow-y: auto;
  }
  
  .selectFeild {
    margin: auto 0px;
    width: 100%;
    max-width: 650px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .userText {
    background: #fff;
    padding: 20px;
    justify-content: center;
    width: 100%;
    max-width: 650px;
    border-radius: 10px;
    text-align: center;
    height: auto;
  }
  
  .userText h3 {
    color: #000;
    font-size: 31px;
    font-weight: 600;
    margin: 15px 0px;
  }
  
  .userText p {
    color: #828282;
    font-size: 17px;
    font-weight: 300;
    max-width: 460px;
  }
  
  .userBtn {
    margin-bottom: 20px;
  }
  
  .txtSlect {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  
  .userBtn {
    margin-top: 40px;
  }
  
  .bottomSpace {
    /* margin-bottom: 10px !important; */
    width: 31px;
  }
  
  /*======UserType Continue button =======*/
  
  .buttonProperty{
    background: #1D4488 !important;
      border: none !important;
      font-weight: 600 !important;
      min-width: 90px;
      max-width: auto;
      padding: 12px 70px !important;
      font-size: 18px;
      font-size: 18px !important;
  }
  
  