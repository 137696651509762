.addSiteProperty {
  padding-bottom: 20px;
}
.addSiteProperty label {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #333333;
}
.addSiteProperty input {
  height: 50px;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
}
.addSiteProperty select {
  height: 50px;
}
.addSiteProperty select .selectIconProperty {
  position: relative;
  top: 31px;
  left: -6px;
}
.addSiteProperty input::placeholder {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
}
/* .addSiteProperty input.form-control{
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: 'Inter';
}
input.addSiteProperty.form-control {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: 'Inter';
} */
.addSiteProperty select {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
}

.CalenderIconProperty {
  width: 30px;
  height: 30px;
  position: relative;
  top: -50px;
  left: 425px;
}

.inputStyle {
  height: 50px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  cursor: pointer;
}
.personField {
  position: relative;
}
.vectorIcon {
  position: absolute;
  top: 21px;
  right: 5px;
}
.personLabel {
  font-size: 18px;
  font-weight: 500;
  font-family: Inter;
}
.personField input::placeholder {
  font-family: "Inter";
  font-size: 15px;
}
.inputLabel {
  font-family: "Inter";
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21.78px;
  text-align: left;
  color: #333333 !important;
}
/* .inputLabel {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

  } */
/* .selectInput{
    max-width: 455px;
 } */
.textAreaLabel {
  font-family: "Inter";
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21.78px;
  text-align: left;
  color: #333333 !important;
}
.textBottom {
  padding-bottom: 20px;
}

/* .inputDevices .MuiSelect-select {
    height: 50px;
    display: flex;
    align-items: center; 
  } */
/* .inputDevices .MuiSelect-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0px;
    right: 0px;
  } */
.inputDevices {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
}

/* .modelTitle {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  color: #000000;
} */
.addSiteProperty::placeholder {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Inter";
}
.placeholder {
  color: #999;
  font-size: 15px;
  font-weight: 400;
  font-family: "Inter";
}
.inputLabel.hidden {
  display: none;
}
.inputLabelPlace {
  font-size: 15px;
  font-weight: 400;
  font-family: "Inter";
}

.personListContainer {
  max-height: 250px; /* Set a height that makes sense for your modal */
  min-height: 50px;
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Optional: Add some spacing */
  scrollbar-width: thin;
  padding-right: 6px;
}
.deviceLabel {
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}
.deviceField {
  position: relative;
}
.deviceField input::placeholder {
  font-family: "Inter";
  font-size: 15px;
}
.deviceListContainer {
  max-height: 250px; /* Set a height that makes sense for your modal */
  min-height: 50px;
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Optional: Add some spacing */
  scrollbar-width: thin;
  padding-right: 6px;
}

.tagLabel {
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}
.tagField {
  position: relative;
}
.tagField input::placeholder {
  font-family: "Inter";
  font-size: 15px;
}
.tagListContainer {
  max-height: 250px; /* Set a height that makes sense for your modal */
  min-height: 50px;
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Optional: Add some spacing */
  scrollbar-width: thin;
  padding-right: 6px;
}
